import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';

import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { HeaderModule} from '../header/header.module';
import { SliderComponent } from './slider/slider.component';
import { NousComponent } from './nous/nous.component';
import { ActivitesComponent } from './activites/activites.component'

import { SharedModule } from '../shared/shared.module';
import { ProjetStartComponent } from './projet-start/projet-start.component'; 

import { UtilsService } from '../services/utils/utils.service';
import { BackendService } from '../services/backend/backend.service';
import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'

import { SafeHtml } from '../pipes/safeHtml.pipe'
import { TruncatePipe } from '../pipes/truncatString.pipe';
import { RealisationsComponent } from './realisations/realisations.component';
@NgModule({
  declarations: [
    HomeComponent, 
    SliderComponent, 
    NousComponent, 
    ActivitesComponent, 
    ProjetStartComponent, RealisationsComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    MaterialAngModule,
    HeaderModule,
    SharedModule
  ],
  providers:[
    UtilsService,
    BackendService,
    EventsService,
    GlobalService
  ],
  exports: [
    NousComponent
  ]
})
export class HomeModule { }
