import { Component, OnInit } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'
import { ModalService } from '../../shared/modal/modal.service';

@Component({
  selector: 'app-activites',
  templateUrl: './activites.component.html',
  styleUrls: ['./activites.component.scss']
})
export class ActivitesComponent implements OnInit {

  listeDomaineActivite = [];
  retourMessage = { ok: null, ko: null };

  indiceEncoursBg = 0;
  iconDomaine = ['eco-home.svg', 'company.svg', 'eco-factory.svg', 'bridge.svg'];
  lengthMax = 0;

  constructor(
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {

    this.displayPage();

  }


  displayPage() {

    this.backendService.get( `/parametrage/list-domaines-activites` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat listeDomaineActivite ', resultat );
         this.listeDomaineActivite = resultat;
         this.indiceEncoursBg = 0;
         this.listeDomaineActivite.forEach( elt => {
           elt.icon = this.iconDomaine[this.indiceEncoursBg];
           this.indiceEncoursBg += 1;
           this.lengthMax = elt.contenu.length > this.lengthMax ? elt.contenu.length : this.lengthMax;
         })
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }


  openURL( page , index ) {
    console.log('open page ', page)
    const color = index%2 === 0 ? 'vert' : 'bleu';

    this.modalService.openModal(
      `${page.topTitre} ${page.titre}`, `${page.contenu} <br /><br /> ${page.description}`, page.image, null, '', 'Fermer', 'Fermer la Fenêtre', color
    ).then( (result : any) => {
      console.log(result)
    })

  }


  addEspaceEndText( text ) {
    let espaceVide = '';
    if ( text.length < this.lengthMax ) {
      for( let i = text.length ; i < this.lengthMax; i++ ) {
        espaceVide += '-';
      }
    }
    return espaceVide;
  }

}
