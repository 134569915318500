import { Component, OnInit } from '@angular/core';

import { BackendService } from '../services/backend/backend.service';
import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'

@Component({
  selector: 'app-realisations',
  templateUrl: './realisations.component.html',
  styleUrls: ['./realisations.component.scss']
})
export class RealisationsComponent implements OnInit {

  lesRealisation = [];
  retourMessage = { ok: null, ko: null };
  limite;
  notOther = false;


  constructor(
    private backendService: BackendService ,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.limite = 0;
    this.displayRealisation()
  }

  displayRealisation() {
    this.backendService.get( `/services/reference/${this.limite}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.lesRealisation = []
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        this.lesRealisation = this.lesRealisation.concat( this.globalService.initList( resultat ) );
        this.notOther = resultat.length < 3 ? true : false
        console.log( 'resultat lesRealisation ', this.lesRealisation );
      }
     })
     .catch( error => {
      this.lesRealisation = []
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }


  voirPlus( ) {
    this.limite = this.lesRealisation.length;
    this.displayRealisation();
  }

}
