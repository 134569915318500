import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact.component';

import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { HeaderModule} from '../header/header.module';
import { SharedModule } from '../shared/shared.module';

import { UtilsService } from '../services/utils/utils.service';
import { BackendService } from '../services/backend/backend.service';
import { GlobalService } from '../services/global/global.service'
@NgModule({
  declarations: [ContactComponent],
  imports: [
    CommonModule,

    MaterialAngModule,
    NgbModule,
    HeaderModule,
    SharedModule
  ],
  providers:[
    UtilsService,
    GlobalService,
    BackendService
  ]
})
export class ContactModule { }
