import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, regexValue: string, replaceValue: string): any {
    let regex = new RegExp(regexValue, 'g');
    let regex_p = new RegExp('<p></p>', 'g');
    let regex_p2 = new RegExp('<p class="MsoNormal"></p>', 'g');
    value = value.replace(regex, replaceValue);
    value = value.replace(regex_p, '');
    return value.replace(regex_p2, '');
  }


}