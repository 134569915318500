import { Component, OnInit } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-bandeau',
  templateUrl: './bandeau.component.html',
  styleUrls: ['./bandeau.component.scss']
})
export class BandeauComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  openURL( page ) {
    console.log('open page ', page)
    this.router.navigate([page]);
    window.scroll(0,0);
  }

}
