import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { HeaderModule} from '../header/header.module';
import { SharedModule } from '../shared/shared.module';
import { HomeModule } from '../home/home.module';

import { UtilsService } from '../services/utils/utils.service';
import { BackendService } from '../services/backend/backend.service';
import { EventsService } from '../services/events/events.service';
import { GlobalService } from '../services/global/global.service';
import { EquipeComponent } from './equipe/equipe.component';
import { LogicielsComponent } from './logiciels/logiciels.component';


@NgModule({
  declarations: [
    MentionsLegalesComponent,
    EquipeComponent,
    LogicielsComponent
  ],
  imports: [
    CommonModule,
    MaterialAngModule,
    NgbModule,
    HeaderModule,
    SharedModule,
    HomeModule
  ],
  providers:[
    UtilsService,
    BackendService,
    EventsService,
    GlobalService
  ]
})
export class PrivacyModule { }
