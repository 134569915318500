import { Pipe , PipeTransform } from '@angular/core';

@Pipe({
    name: 'stringRemove'
})
export class StringRemove implements PipeTransform {

    transform(text: string, value,  arrayReplace: any[]): string {
        let str = text;
        arrayReplace.forEach(elt => {
            str = str.includes( elt ) ? str.replace( elt , value) : str;
        });
        return str;
    }
}