import { Component, OnInit } from '@angular/core';

import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'
@Component({
  selector: 'app-competences',
  templateUrl: './competences.component.html',
  styleUrls: ['./competences.component.scss']
})
export class CompetencesComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  competencesArray = []


  constructor(
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage()
  }

  displayPage() {

    this.backendService.get( `/parametrage/list-competences` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ==> ==> ', resultat );
         this.competencesArray = resultat;
         console.log( this.competencesArray[0].contenu )
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

}
