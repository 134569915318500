import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialAngModule } from '../matmodule/materialang.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { UtilsService } from '../services/utils/utils.service';
import { BackendService } from '../services/backend/backend.service';
import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule ,
    MaterialAngModule,
    NgbModule
  ],
  exports: [HeaderComponent],
  providers:[
    UtilsService,
    BackendService,
    EventsService,
    GlobalService
  ]
})
export class HeaderModule { }
