import { Component, OnInit } from '@angular/core';
import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-equipe',
  templateUrl: './equipe.component.html',
  styleUrls: ['./equipe.component.scss']
})
export class EquipeComponent implements OnInit {

  lEquipe = [];
  lesGerants = [];

  retourMessage = { ok: null, ko: null };

  constructor(
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage();
  }


  displayPage() {

    this.backendService.get( `/teams/list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat lEquipe ', resultat );
        this.displayGerants ( resultat );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })

  }


  displayGerants( resultat : any[] ) {
    this.lesGerants = resultat.filter( elt =>  elt.fonction !== "Collaborateur" );
    this.lEquipe = resultat.filter( elt =>  elt.fonction === "Collaborateur" );
  }

}
