import { Component, OnInit } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { UtilsService } from '../services/utils/utils.service';
import { BackendService } from '../services/backend/backend.service';
import { EventsService } from '../services/events/events.service'
import { GlobalService } from '../services/global/global.service'

@Component({
  selector: 'app-les-services',
  templateUrl: './les-services.component.html',
  styleUrls: ['./les-services.component.scss']
})
export class LesServicesComponent implements OnInit {

  lesService = [];
  removable = false;
  selectable = true;

  retourMessage = { ok: null, ko: null };
  leServiceArray = [];

  constructor(
    private utilsService: UtilsService,
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.lesService = this.utilsService.formatURLService();
    this.displayPage();
  }



  displayPage() {

    this.backendService.get( `/services/service/service` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat leServiceArray ', resultat );
        this.leServiceArray = this.globalService.initList( resultat );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }

  checkImage() {
    if ( Array.isArray( this.leServiceArray[0].images ) ) {
      return this.leServiceArray[0].images.length > 0 ? this.leServiceArray[0].images[0].url_send : "assets/imgs/Construction.svg"
    } else {
      return "assets/imgs/Construction.svg"
    }
  }

  checkIsrayImage() {
    return Array.isArray( this.leServiceArray[0].images )
  }

  checkIsArray( arraySend ) {
    return Array.isArray( arraySend )
  }


  selectElement(service) {
    console.log('Service selected : ' , service)
    this.router.navigate([service.page]);
    window.scroll(0,0);
  }

}
