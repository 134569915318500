

<!-- partie videos --> 
<app-top-page [titrePage]="titreImages" *ngIf="checkIsray() && imagesObject.length > 0" [colorBase]="colorBase" ></app-top-page>

<!-- class="background-white padding-bottom-10 padding-top-10 margin-top-10 row one-edge-shadow"  -->
<div class="row padding-40-left-right background-white padding-bottom-10 padding-top-10 margin-top-10 row one-edge-shadow" *ngIf="checkIsray() && imagesObject.length > 0" >

    <div class="group-2 cursor ">

        <div>
            <img *ngFor="let img of imagesObject; let i = index" [src]="img.image | safeHtml " (click)="showLightbox(imagesObject, i)" />
        </div>
    </div>
    <ng-image-fullscreen-view
        [images]="imagesObject"
        [imageIndex]="currentIndex"
        [show]="visiableLightbox"
        (close)="closeHandler()">
    </ng-image-fullscreen-view>
</div>
