import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {YouTubePlayerModule} from '@angular/youtube-player';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { NgxMaskModule } from 'ngx-mask';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { SafeHtml } from '../pipes/safeHtml.pipe'
import { TruncatePipe } from '../pipes/truncatString.pipe';
import { StringRemove } from '../pipes/stringRemove.pipe';
import { ReplacePipe } from '../pipes/replace.pipe';
import { ModalService } from './modal/modal.service';
import { BackendService } from '../services/backend/backend.service';
import { GlobalService } from '../services/global/global.service';

import { BandeauComponent } from './bandeau/bandeau.component';
import { FooterComponent } from './footer/footer.component';
import { TopPageComponent } from './top-page/top-page.component';
import { DisplayTextImageComponent } from './display-text-image/display-text-image.component';
import { YoutubeVideoComponent } from './youtube-video/youtube-video.component';
import { CompetencesComponent } from './competences/competences.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ModalComponent } from './modal/modal.component';
import { FormulaireComponent } from './formulaire/formulaire.component';
import { ValidatorrService } from '../services/validatorr/validatorr.service';
import { InfosAgenceComponent } from './infos-agence/infos-agence.component';
import { ContentParamComponent } from './content-param/content-param.component'
import { DispalyVideosComponent } from './dispaly-videos/dispaly-videos.component';
import { DispalyItemlistComponent } from './dispaly-itemlist/dispaly-itemlist.component';
import { ImageGalleryComponent } from './image-gallery/image-gallery.component'
import { ContactInfosComponent } from './contact-infos/contact-infos.component';
import { LoaderComponent } from './loader/loader.component';
import { GerantComponent } from './gerant/gerant.component';
import { FooterMenuMobileComponent } from './footer-menu-mobile/footer-menu-mobile.component';
import { LoadPdfComponent } from './load-pdf/load-pdf.component';
import { LoadPdfService } from './load-pdf/load-pdf.service';
import { PortfolioTwoComponent } from './portfolio-two/portfolio-two.component';


@NgModule({
  declarations: [
    BandeauComponent,
    FooterComponent,
    TopPageComponent,
    DisplayTextImageComponent,
    YoutubeVideoComponent,
    CompetencesComponent,
    PortfolioComponent,
    ModalComponent,
    FormulaireComponent,
    InfosAgenceComponent,
    ContentParamComponent,
    DispalyVideosComponent,
    DispalyItemlistComponent,
    ImageGalleryComponent,
    SafeHtml,
    TruncatePipe,
    ContactInfosComponent,
    LoaderComponent,
    StringRemove,
    ReplacePipe,
    GerantComponent,
    FooterMenuMobileComponent,
    LoadPdfComponent,
    PortfolioTwoComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule ,
    YouTubePlayerModule,
    NgxMaskModule.forRoot(),
    NgImageFullscreenViewModule,
    PdfViewerModule
  ],
  exports: [
    BandeauComponent,
    FooterComponent,
    TopPageComponent,
    DisplayTextImageComponent,
    YoutubeVideoComponent,
    CompetencesComponent,
    PortfolioComponent,
    ModalComponent,
    FormulaireComponent,
    InfosAgenceComponent,
    ContentParamComponent,
    DispalyVideosComponent,
    DispalyItemlistComponent,
    ImageGalleryComponent,
    SafeHtml,
    TruncatePipe,
    ContactInfosComponent,
    LoaderComponent,
    StringRemove,
    ReplacePipe,
    GerantComponent,
    FooterMenuMobileComponent,
    LoadPdfComponent,
    PortfolioTwoComponent
  ],
  providers: [
    ModalService,
    ValidatorrService,
    BackendService,
    GlobalService,
    LoadPdfService
  ]
})
export class SharedModule { }
