

<app-top-page [titrePage]="titredelaPage" ></app-top-page>

<div class="background-white container-fluid">
    <div class="row ">
        <div class="col-md-6 border-red partie-image-manager">
            <img [src]="gerantArray.contenu[0].image | safeHtml" [alt]="titredelaPage" class="img-fluid-gerant " />
        </div>

        <div class="col-md-6">
            <div class="text-14 text-align-left gerant-div padding-20-all " [innerHTML]="gerantArray.contenu[0].contenu" ></div>
        </div>
    </div>
</div>

