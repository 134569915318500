
<div >

    <div id="wrapper" class="slider-page" >

        <ngb-carousel [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators" >
    
            <ng-template ngbSlide *ngFor="let slide of sliderList; let i = index;" >

            <div class="main-slider"
                [ngStyle]="{'background-image': 'url(assets/slider/bg/' + slide.bg + ')', 'background-size': '100% 100%', 'background-repeat': 'no-repeat'}">

                <div class="content-slider-div " > 
        
                    <div class="camera_caption fadeFromLeft">
                        <div class="container container-slider">

                            <div *ngIf="i%2 === 0" class="row cursor" (click)="openURL(slide)" >
                                <div class="col-lg-6 cursor"  >
                                    <div class="animated fadeInDown colorVert cursor text-h3"><strong> {{slide.titre}} </strong></div>
            
                                    <div class="animated fadeInUp text-14 " [innerHTML]="slide.descriptions" > </div> <br />
                                    <a class="btn btnVertClaire btn-large animated fadeInUp text-12 cursor" >
                                        <i class="icon-link"></i> {{slide.textBtn}}
                                    </a>
                                </div>

                                <div class="d-none d-lg-block col-lg-6 imageClass cursor text-align-right">
                                    <img [src]="slide.image | safeHtml  " alt="" class="animated bounceInDown delay1" />
                                </div>
                            </div>

                            <div *ngIf="i%2 !== 0" class="row cursor" (click)="openURL(slide)">
                                <div class="d-none d-lg-block col-lg-6 imageClass cursor">
                                    <img [src]="slide.image | safeHtml  " alt="" class="animated bounceInDown delay1" />
                                </div>
<!-- [ngStyle]="{'background-image': 'url(assets/slider/bg/' + slide.bg + ')', 'background-size': 'contain', 'background-repeat': 'no-repeat'}"  -->
                                <div class="col-lg-6 cursor" >
                                    <div class="animated fadeInDown colorBleu text-h3"><strong> {{slide.titre}} </strong></div>
                                    <div class="animated fadeInUp text-14 " [innerHTML]="slide.descriptions" > </div> <br />
                                    <a class="btn btnBleu btn-large animated fadeInUp text-12 cursor" >
                                        <i class="icon-link"></i> {{slide.textBtn}}
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
              
    
            </ng-template>
    
          </ngb-carousel>
    
    </div>
</div>




