import { Component, OnInit } from '@angular/core';
import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service';
import { LoadPdfService } from '../../shared/load-pdf/load-pdf.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  infosAgence = {
    adresseComores: "Moroni Coulé",
    adresseFrance: "30 rue de la Solidarité<br />93800 Epinay-sur-seine",
    telOne: "00 00 00 00 00",
    telTwo: "00 00 00 00 00",
    telThree: "00 00 00 00 00",
    email: "email@test.fr",
    social: [
      {type: 'facebook', url: '', active: true, icone: 'assets/social/facebook.png'},
      {type: 'twitter', url: '', active: true, icone: 'assets/social/twitter.png'},
      {type: 'instagram', url: '', active: true, icone: 'assets/social/instagram.png'},
      {type: 'linkedin', url: '', active: true, icone: 'assets/social/linkedin.png'},
      {type: 'youtube', url: '', active: true, icone: 'assets/social/youtube.png'}
    ]
  }

  leFooterArray = []
  retourMessage = { ok: null, ko: null };
  
  constructor(
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService,
    private loadPdfService: LoadPdfService
  ) { }

  ngOnInit(): void {
    this.displayPage();
  }

  openPage(page) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }

  displayPage() {
    this.leFooterArray = this.globalService.getInfosContact();
  }

  openUrl(url) {
    window.open(url, '_blank')
  }

  openPlaquette() {
    this.loadPdfService.openLoadPDF(
      'Plaquette - Constans Ingénierie ',
      'assets/plaquette/plaquette_1.pdf',
      'Fermer'
    ).then( (result : any) => {
      console.log(result)
    })
  }

}
