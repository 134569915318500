import { Component, OnInit } from '@angular/core';

import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-gerant',
  templateUrl: './gerant.component.html',
  styleUrls: ['./gerant.component.scss']
})
export class GerantComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  gerantArray;
  titredelaPage = ''

  constructor(
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage()
  }

  displayPage() {
    this.backendService.get( `/parametrage/byidentifiant/agence-gest` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        resultat[0].contenu = JSON.parse( resultat[0].contenu );
        this.titredelaPage = resultat[0].titre;
        this.gerantArray = resultat[0];
        console.log( 'resultat ==> ==> Gerant', this.gerantArray );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

}
