import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private lesService = [
    {
      id: 2,
      libelle: 'Étude de faisabilité du projet',
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: false,
      selectedID: '2-1'
    },
    {
      id: 2,
      libelle: "Plan d'architecture / Plan d'exécution",
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: false,
      selectedID: '2-2'
    },
    {
      id: 2,
      libelle: "Élaboration des DCE",
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: false,
      selectedID: '2-3'
    },
    {
      id: 2,
      libelle: "Estimation budgetaire",
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: false,
      selectedID: '2-4'
    },
    {
      id: 2,
      libelle: "Implantation et suivi des travaux",
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: false,
      selectedID: '2-5'
    },
    {
      id: 2,
      libelle: "Réalisation de note de calcul",
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: false,
      selectedID: '2-6'
    },
    {
      id: 2,
      libelle: "Assistance et contrôle technique",
      collaps: false,
      collapsed: [],
      page: '/home',
      endItem: true,
      selectedID: '2-7'
    }
  ];

  private menuList = [
    {
      id: 1,
      libelle: 'Accueil',
      collaps: false,
      collapsed: [],
      show: true,
      page: '/home',
      icon: 'home'
    },
    {
      id: 2,
      libelle: 'Services',
      collaps: true,
      collapsed: this.formatURLService(),
      show: true,
      page: '/nos-services',
      icon: 'architecture'
    },
    {
      id: 3,
      libelle: 'Références',
      collaps: false,
      collapsed: [],
      show: true,
      page: '/nos-realisations',
      icon: 'precision_manufacturing'
    },
    {
      id: 4,
      libelle: 'Contact',
      collaps: false,
      collapsed: [],
      show: true,
      page: '/nous-contacter',
      icon: 'email'
    },
    {
      id: 5,
      libelle: 'Mentions légales',
      collaps: false,
      collapsed: [],
      show: false,
      page: '/nos-mentions-legales'
    },
    {
      id: 6,
      libelle: 'Gérer mes cookies',
      collaps: false,
      collapsed: [],
      show: false,
      page: '/gerer-mes-cookies'
    },
    {
      id: 7,
      libelle: 'Qui sommes-nous',
      collaps: false,
      collapsed: [],
      show: false,
      page: '/qui-sommes-nous'
    },
    {
      id: 8,
      libelle: 'Logiciels utilisés',
      collaps: false,
      collapsed: [],
      show: false,
      page: '/logiciels-utilises'
    }
  ];

  constructor() { }

  getMenuList() {
     const menu = this.menuList.filter(elmt => elmt.show === true );
     console.log( ' menu menu menu menu  ', menu )
    return menu;
  }


  checkConcat(vehicles: any[]) : any[] {
    return vehicles;
  }

  getMenuByPage( page ) {
    return this.menuList.filter(elmt => elmt.page === page );
  }


  getElementByIDPage( element, value,  listArray ) {
    return listArray.filter(elmt => elmt[element] === value )[0];
  }

  getLesServices() {
    return this.lesService
  }

  formatStringForURL( stringtxt ) {

    // stringtxt = stringtxt.replace(/[^\w\s]/gi, '')
    stringtxt = stringtxt.replace(/\s/g, '-');
    stringtxt = stringtxt.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    stringtxt = stringtxt.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '-');
    stringtxt = stringtxt.replace('---', '-')
    stringtxt = stringtxt.replace('--', '-')
    stringtxt = stringtxt.replace('----', '-')
    stringtxt = stringtxt.replace('------', '')
    return stringtxt.toLowerCase();
  }

  formatURLService() {
    return this.lesService.map( elt => {
      elt.page = `nos-services/details-services/${this.formatStringForURL(elt.libelle)}/${elt.selectedID}` // 'details-services/:titre/:id'
      return elt;
    });
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  

}
