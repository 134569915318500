import { Component, OnInit } from '@angular/core';
import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'


@Component({
  selector: 'app-logiciels',
  templateUrl: './logiciels.component.html',
  styleUrls: ['./logiciels.component.scss']
})
export class LogicielsComponent implements OnInit {

  lesLogiciels = [];
  imagesLogicels = [];
  retourMessage = { ok: null, ko: null };

  constructor(
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage()
    this.getImagesLogiciel()
  }

  displayPage( ) {

    this.backendService.get( `/parametrage/byidentifiant/app-use` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
        resultat[0].images = resultat[0].contenu;
         this.lesLogiciels = this.globalService.initList( resultat );
         console.log( 'resultat lesLogiciels ', this.lesLogiciels );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }


  getImagesLogiciel() {
    this.backendService.get( `/parametrage/list-images-logiciels` )
    .then( result => {
      if( result.hasOwnProperty('status') && this.globalService.getErrorCode().includes( result.status ) ){
        this.retourMessage.ko = result.status != 0 ? result.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {

        this.imagesLogicels = this.globalService.initImageLogiciel( result );
         
         console.log( 'result imagesLogicels==>  ', result );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

}
