
<div class="menu-fixed-bottom fixed-bottom d-blobk d-lg-none background-white border-top-footer-menu">

    <div class="row">
        <div class="col text-center text-12 cursor" *ngFor="let mnl of menuList; let idxx = index"
            [ngClass] = "[idxx%2===0 ? 'vertMenu' : 'bleuMenu' , mnl.id === selectedPage.id ? (idxx%2===0 ? 'urlActiveVert' : 'urlActiveBleu') : '']"
            (click)="openPage(mnl)"
        >
            <span class="material-icons icon-center">{{mnl.icon}}</span><br />
            {{mnl.libelle}}
        </div>
    </div>

</div>
