import { Component, OnInit } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'
import { ModalService } from '../../shared/modal/modal.service';
@Component({
  selector: 'app-projet-start',
  templateUrl: './projet-start.component.html',
  styleUrls: ['./projet-start.component.scss']
})
export class ProjetStartComponent implements OnInit {

  servicePourTousArray = [];
  retourMessage = { ok: null, ko: null };
  maxItemList = Array(3).fill(3)

  constructor(
    private backendService: BackendService,
    private globalService: GlobalService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.displayPage();
  }

  displayPage() {

    this.backendService.get( `/parametrage/list-service-pour-tous` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat servicePourTousArray ', resultat );
         this.servicePourTousArray = this.globalService.initList( resultat );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

  openDetailPage( page , index ) {
    console.log('open page ', page, index)

    const image = index == 0 ? 'assets/imgs/house.svg' : 'assets/imgs/office.svg';

    this.modalService.openModal(
      `${page.titre}`, `${page.contenu} <br /><br /> ${page.description}`, image, null, page.listItem, '', 'Fermer'
    ).then( (result : any) => {
      console.log(result)
    })

  }

}
