import { Component, OnInit } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  showNavigationArrows = true;
  showNavigationIndicators = true;
  sliderList = [];
  retourMessage = { ok: null, ko: null };
  indiceEncoursBg = 0;
  bgSlide = ['bg1.jpg', 'bg2.jpg', 'bg3.jpg', 'bg4.jpg', 'bg5.jpg']


  constructor(
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService
  ) {
  }

  ngOnInit(): void {
    this.displayPage()
  }


  displayPage() {

    this.backendService.get( `/sliders/list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.sliderList = []
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ', resultat );
         this.sliderList = resultat;
         this.indiceEncoursBg = 0;
         this.sliderList.forEach( elt => {
          if ( this.indiceEncoursBg > this.bgSlide.length ) {
            this.indiceEncoursBg = 0;
          }
           elt.bg = this.bgSlide[this.indiceEncoursBg];
           this.indiceEncoursBg += 1;
         })
      }
     })
     .catch( error => {
       this.sliderList = []
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

  getRandomBg() {
    if ( this.indiceEncoursBg > this.bgSlide.length ) {
      this.indiceEncoursBg = 0;
    }
    this.indiceEncoursBg += 1;
    return this.bgSlide[this.indiceEncoursBg - 1];
  }

  openURL( page ) {
    console.log('open page ', page)
    this.router.navigate([page.url]);
    window.scroll(0,0);
  }

}
