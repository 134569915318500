import { Component, OnInit } from '@angular/core';

import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-infos-agence',
  templateUrl: './infos-agence.component.html',
  styleUrls: ['./infos-agence.component.scss']
})
export class InfosAgenceComponent implements OnInit {

  infosAgenceArray = [];

  constructor(
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage();
  }

  displayPage() {
    this.infosAgenceArray = this.globalService.getInfosContact();
  }

  openUrl(url) {
    window.open(url, '_blank')
  }

}
