import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadPdfComponent } from './load-pdf.component'


@Injectable({
  providedIn: 'root'
})
export class LoadPdfService {

  constructor(private pdfLoadService: NgbModal) { }

  public openLoadPDF(
    title: string,
    urlpdf: string,
    btnOkText: string = 'Valider',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.pdfLoadService.open(LoadPdfComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.urlpdf = urlpdf;
    modalRef.componentInstance.btnOkText = btnOkText;
    return modalRef.result;
  }
  
}
