import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public urlImage = environment.urlImage;

  private infoContact;

  constructor() { }

  setInfosContact( infos ) {
    localStorage.setItem('constns_infosGlobal', JSON.stringify( infos ))
  }

  getInfosContact() {
    return localStorage.getItem('constns_infosGlobal') ? JSON.parse(localStorage.getItem('constns_infosGlobal')) : null
  }

  getURLApp() {
    return environment.urlApp
  }

  setCookiesBrowser(){
    let cookies_ = {
      productSub: navigator.productSub,
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      appVersion: navigator.appVersion,
      appName: navigator.appName
    }
    localStorage.setItem('constns_cookiesAcceptUser', JSON.stringify( cookies_ ))
  }

  getCookiesBrowserMs(){
    return localStorage.getItem('constns_cookiesAcceptUser') ? JSON.parse(localStorage.getItem('constns_cookiesAcceptUser')) : null
  }

  dateDiff(date1, date2){
      var diff = {sec: null, min: null, hour: null, day: null}                           // Initialisation du retour
      var tmp = date2 - date1;
  
      tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60;                    // Extraction du nombre de secondes
  
      tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
      diff.min = tmp % 60;                    // Extraction du nombre de minutes
  
      tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
      diff.hour = tmp % 24;                   // Extraction du nombre d'heures
      
      tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
      diff.day = tmp;
      
      return diff;
  }

  setVisitor( visitor ){
    if( visitor !== undefined && visitor !== null) {
      console.log('Visitor :: ', visitor )
      localStorage.setItem('constns_visitoXztrPApp', JSON.stringify( visitor ))
    }
      
  }

  getVisitor(){
    return localStorage.getItem('constns_visitoXztrPApp') && localStorage.getItem('constns_visitoXztrPApp') !== "undefined" ? JSON.parse(localStorage.getItem('constns_visitoXztrPApp')) :  null 
  }

  randomString(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKL.MNOPQRSTUVWXYZabcde-fghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  makeid(length) {
    var result           = '';
    var characters       = '123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getErrorCode(){
    return [
      0, 401, 403, 404, 422, 500, 501, 503, 301, 302 
    ];
  }


  initList( arrayBoject ){
    for (var i = 0; i < arrayBoject.length; i++) {

      if ( arrayBoject[i].listItem ) {
        arrayBoject[i].listItem = arrayBoject[i].listItem === "" ? [] : JSON.parse( arrayBoject[i].listItem )
      }

      if ( arrayBoject[i].images ) {
        arrayBoject[i].images = arrayBoject[i].images === "" ? [] : JSON.parse( arrayBoject[i].images )
      }
      if ( arrayBoject[i].telephone_fr ) {
        arrayBoject[i].telephone_fr = arrayBoject[i].telephone_fr === "" ? [] : JSON.parse( arrayBoject[i].telephone_fr )
      }
      if ( arrayBoject[i].telephone_km ) {
        arrayBoject[i].telephone_km = arrayBoject[i].telephone_km === "" ? [] : JSON.parse( arrayBoject[i].telephone_km )
      }
      if ( arrayBoject[i].reseaux ) {
        arrayBoject[i].reseaux = arrayBoject[i].reseaux === "" ? [] : JSON.parse( arrayBoject[i].reseaux )
      }
    }
    return arrayBoject
  }


  iniSocialnetwork( arrayBoject ) {
    for (var i = 0; i < arrayBoject.length; i++) {
      if ( arrayBoject[i].value_reseau_ctrl.includes( 'facebook' ) ) {
        arrayBoject[i].icone = 'assets/social/facebook.png'
      }
      if ( arrayBoject[i].value_reseau_ctrl.includes( 'twitter' ) ) {
        arrayBoject[i].icone = 'assets/social/twitter.png'
      }
      if ( arrayBoject[i].value_reseau_ctrl.includes( 'instagram' ) ) {
        arrayBoject[i].icone = 'assets/social/instagram.png'
      }
      if ( arrayBoject[i].value_reseau_ctrl.includes( 'linked' ) ) {
        arrayBoject[i].icone = 'assets/social/linkedin.png'
      }
      if ( arrayBoject[i].value_reseau_ctrl.includes( 'youtube' ) ) {
        arrayBoject[i].icone = 'assets/social/youtube.png'
      }
    }
    return arrayBoject;
  }


  initImageLogiciel( arrayBojectt ){
    for (var i = 0; i < arrayBojectt.length; i++) {
      // arrayBojectt[i].url = `${environment.urlImage}${arrayBojectt[i].url}`
      arrayBojectt[i].url_send = `${environment.urlImage}${arrayBojectt[i].url}`
    }
    return arrayBojectt;
  }



}
