import { Component, Input, OnInit } from '@angular/core';

import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'
@Component({
  selector: 'app-nous',
  templateUrl: './nous.component.html',
  styleUrls: ['./nous.component.scss']
})
export class NousComponent implements OnInit {

  quiSommeNous = [];
  retourMessage = { ok: null, ko: null };
  @Input() quiNous = 'accueil';

  constructor(
    private router: Router,
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage();
  }


  displayPage() {

    this.backendService.get( `/parametrage/byidentifiant/3-2` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat ', resultat );
        resultat[0].contenu = JSON.parse( resultat[0].contenu );
        this.quiSommeNous = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })

  }

  openURL( page ) {
    console.log('open page nous ', page)
    this.router.navigate([page]);
    window.scroll(0,0);
  }



}
