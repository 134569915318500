

<app-top-page 
[titrePage]="titreItems" *ngIf="listOfItem && chechIsAyya() &&  listOfItem.length > 0" 
[colorBase]="colorBase"
></app-top-page>

<div class="row padding-20-all background-white padding-bottom-10 padding-top-10 margin-top-10 row one-edge-shadow" *ngIf="listOfItem && chechIsAyya() &&  listOfItem.length > 0" >
    <div class="col-md-6" *ngFor="let itmlst of listOfItem; let indItem=index " >
        <ul class="fa-ul">

            <li class="text-left text-12" >
                <span [ngClass]="colorBase === 'vert' ? 'fa-li-vert' : 'fa-li-bleu' " >
                    <span class="material-icons">done</span>
                </span>
                {{itmlst.value_item_ctrl}}
            </li>

        </ul>
    </div>
</div>
