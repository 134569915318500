


<div class="">

    <div class="row">
        <div class="col-4 colorBleu text-12"> <span class="material-icons"> email</span> </div>
        <div class="col-8 text-12 "> {{leFooterArray[0].email}} </div>
    </div>

    <div class="vide text-14 text-bold colorBleu traitBas" *ngIf="leFooterArray[0].adresse_fr != '' " >
        France
    </div>

    <div class="row" *ngIf="leFooterArray[0].adresse_fr != '' ">
        <div class="col-4 colorBleu text-12"> <span class="material-icons"> location_on</span> </div>
        <div class="col-8 text-12 "> {{leFooterArray[0].adresse_fr}} </div>
    </div>

    <div class="row" *ngIf="leFooterArray[0].telephone_fr  && leFooterArray[0].telephone_fr.length > 0 " >
        <div class="col-4 colorBleu text-12"> <span class="material-icons"> phone</span> </div>
        <div class="col-8">
            <div class="row">
                <div class="col-12 text-12 " *ngFor="let telfr of leFooterArray[0].telephone_fr" >
                    <span > {{ telfr.value_tel_fr_ctrl | mask: "(+00) 00 00 00 00 00" }}  </span>
                </div>
            </div>
        </div>
    </div>

    <div class="vide text-14 text-bold colorBleu traitBas" *ngIf="leFooterArray[0].adresse_km != '' " >
        Comores
    </div>

    <div class="row" *ngIf="leFooterArray[0].adresse_km != '' ">
        <div class="col-4 colorBleu text-12"> <span class="material-icons"> location_on</span> </div>
        <div class="col-8 text-12 "> {{leFooterArray[0].adresse_km}} </div>
    </div>

    <div class="row" *ngIf="leFooterArray[0].telephone_km  && leFooterArray[0].telephone_km.length > 0 " >
        <div class="col-4 colorBleu text-12"> <span class="material-icons"> phone</span> </div>
        <div class="col-8">
            <div class="row">
                <div class="col-12 text-12 " *ngFor="let telfr of leFooterArray[0].telephone_km" >
                    <span > {{ telfr.value_tel_km_ctrl | mask: "(+000) 000 00 00" }}  </span>
                </div>
            </div>
        </div>
    </div>
    
    
</div>