import { Injectable } from '@angular/core';
import { Output , EventEmitter } from '@angular/core';

import { Subject, Observable , BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class EventsService {

  @Output() pageEmitter = new EventEmitter();
  @Output() majTokenUser = new EventEmitter();

  constructor() { }

  onPageSelected( page ){
    this.pageEmitter.emit( page )
  }

  onMajToken(){
    this.majTokenUser.emit()
  }


}
