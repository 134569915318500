import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';

import { LesServicesComponent } from './les-services/les-services.component';
import { DetailsServiceComponent } from './les-services/details-service/details-service.component';

import { RealisationsComponent } from './realisations/realisations.component';
import { ContactComponent } from './contact/contact.component';

import { MentionsLegalesComponent } from './privacy/mentions-legales/mentions-legales.component';
import { EquipeComponent } from './privacy/equipe/equipe.component';
import { LogicielsComponent } from './privacy/logiciels/logiciels.component';

const routes: Routes = [
  { path: 'home',      component: HomeComponent },
  { path: 'nos-services',      component: LesServicesComponent },
  { path: 'nos-services/details-services/:titre/:id',      component: DetailsServiceComponent },

  { path: 'nos-realisations',      component: RealisationsComponent },
  
  { path: 'nous-contacter',      component: ContactComponent },

  { path: 'nos-mentions-legales',      component: MentionsLegalesComponent },
  { path: 'qui-sommes-nous',      component: EquipeComponent },
  { path: 'logiciels-utilises',      component: LogicielsComponent },

  { path: '',   redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: HomeComponent }
];

// imports: [RouterModule.forRoot(routes , { useHash: true } )],
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
