<div class="portfolio-modal " >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">


            <span (click)="decline()" class="close cursor material-icons fa-3x-size displayBtnClose" [ngClass]="colorBase === 'vert' ? 'colorVert' : 'colorBleu'" > close</span>

            <div class="modal-body text-center">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <!-- Portfolio Modal - Title-->
                            <div *ngIf="title !== ''" class="portfolio-modal-title mb-0 text-bold-1-3em colorDarkHours " id="portfolioModal1Label">
                                {{title}}
                            </div>
                            <!-- Icon Divider-->
                            <div [ngClass]="colorBase === 'vert' ? 'divider-custom-vert' : 'divider-custom-bleu'">
                                <div class="divider-custom-line"></div>
                                <div class="divider-custom-icon"> <span class="material-icons fa-3x-size " [ngClass]="colorBase === 'vert' ? 'colorVert' : 'colorBleu'">star</span> </div>
                                <div class="divider-custom-line"></div>
                            </div>

                            <!-- Portfolio Modal - Image-->
                            <!-- Multiple image list -->
                                
                                <div *ngIf="!listOfImage || listOfImage.length == 0 || listOfImage.length == 1 ">
                                    <img *ngIf="imageSelected !== ''" class="img-fluid rounded mb-5  " [src]="imageSelected | safeHtml " alt="Constans - {{title}}" />
                                </div>
<!-- [activeId]="slideEncours"    id="slider_number_{{i}}"   -->
                                <div class="row justify-content-center" *ngIf="listOfImage && listOfImage.length > 1" >
                                    <ngb-carousel #carousel [activeId]="slideEncours" [interval]="3000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" >
                                        <ng-template ngbSlide *ngFor="let imgl of listOfImage; let i = index;" id="slider_number_{{i}}" >
                                            <div class="main-caroussel" >
                                                <img class="img-slider img-fluid" [src]="imgl.url_send | safeHtml" alt="Constans - {{title}}" />
                                            </div>
                                        </ng-template>
                                    </ngb-carousel>
                                </div>

                                <div class="modeWebImages">
                                    <div class=" row justify-content-center top-carousel" *ngIf="listOfImage && listOfImage.length > 1" >
                                        <!-- Portfolio Item 1-->
                                        <div class="col-md-2 col-lg-2 cursor" *ngFor="let mlll of listOfImage; let ii = index;" >
                                            <div class="portfolio-item mx-auto" (click)="choseImage(mlll.url_send, ii)" >
                                                <img class="img-fluid" [src]="mlll.url_send | safeHtml" alt="Constans - {{title}}" />
                                            </div>
                        
                                        </div>
                                    </div>
                                </div>
                                

            
                            
                            <!--<div class="modeMobileImages">

                                <div *ngIf="!listOfImage || listOfImage.length == 0">
                                    <img *ngIf="imageSelected !== ''" class="img-fluid rounded mb-5  " [src]="imageSelected | safeHtml " alt="Constans - {{title}}" />
                                </div>

                                <div class="row justify-content-center" *ngIf="listOfImage && listOfImage.length > 0" >
                                    <ngb-carousel showNavigationArrows="false" showNavigationIndicators="true" id="second"  >
                                        <ng-template ngbSlide *ngFor="let imgl of listOfImage; let i = index;" >
                                            <div class="main-caroussel">
                                                <img class="img-fluid-ct" [src]="imgl.url_send | safeHtml" alt="Constans - {{title}}" />
                                            </div>
                                        </ng-template>
                                    </ngb-carousel>
                                </div>
                            </div>-->

                            <!-- Liste of items -->
                            <div class="row margin-top-20px" *ngIf="listOfItem && listOfItem.length > 0" >
                                <div class="col-md-6" *ngFor="let itmlst of listOfItem; let indItem=index " >
                                    <ul class="fa-ul">
                
                                        <li class="text-left text-12" >
                                            <span class="fa-li">
                                                <span class="material-icons">done</span>
                                            </span>
                                            {{itmlst.value_item_ctrl}}
                                        </li>
                
                                    </ul>
                                </div>
                            </div>

                            <div [ngClass]=" listOfImage && listOfImage.length > 0 ? 'margin-top-10' : '' "></div> 
                            <!-- Portfolio Modal - Text-->
                            <div class="text-left text-12 mb-5 " 
                                [ngClass]="colorBase === 'vert' ? 'text-justify-vert' : 'text-justify-bleu'"
                                
                                *ngIf="description !== ''" 
                                [innerHTML]="description  " 
                            ></div>
                            <button class="btn btnClass" [ngClass]="colorBase === 'vert' ? 'btnVert' : 'btnBleu'" (click)="decline()" >
                                <span class="material-icons fa-1x-size">close</span>
                                {{btnCancelText}}
                            </button>

                            <br /><br /><br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


