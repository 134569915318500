
<div class="publicite-div border-red " *ngIf="quiSommeNous.length > 0" >

    <div class="title-pub text-h3 margin-top-20px margin-bottom-20 "> 
        {{ quiSommeNous[0].titre }}
    </div>


    <div class="container-fluid">

        <div class="row one-edge-shadow padding-20-all background-white espace-top-margin ">
            <div class="col-md-6 ">
                <div class="image-pub" (click)="openURL('/qui-sommes-nous')">
                    <img src="assets/imgs/about_us.png" class="pub-svg animated bounceInLeft delay1 cursor" />
                </div>
            </div>
    
            <div class="col-md-6 text-justify">
                <div class="text-pub text-12 text-justify" 
                    [innerHTML]=" quiNous === 'accueil' ? quiSommeNous[0].contenu[0].contenu : quiSommeNous[0].contenu[1].contenu" 
                > </div>
            </div>
        </div>

    </div>

    

</div>
