import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';


import { BackendService } from  '../../services/backend/backend.service';
import { EventsService } from  '../../services/events/events.service'
import { GlobalService } from  '../../services/global/global.service'

@Component({
  selector: 'app-details-service',
  templateUrl: './details-service.component.html',
  styleUrls: ['./details-service.component.scss']
})
export class DetailsServiceComponent implements OnInit {

  servicesData;
  typeDisplay;
  idService;

  retourMessage = { ok: null, ko: null };
  leServiceArray = [];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utilsService: UtilsService,
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(routeParams => {
      console.log( 'routeParams :: ', routeParams)
      this.typeDisplay = this.utilsService.getRandomInt(2) === 0 ? 'first' : 'second';
      this.idService = routeParams.id;
      this.displayPage();
    });
  }


  displayPage() {

    this.backendService.get( `/services/service/${this.idService}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat leServiceArray ', resultat );
        this.leServiceArray = this.globalService.initList( resultat );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }

  checkImage() {
    if ( Array.isArray( this.leServiceArray[0].images ) ) {
      return this.leServiceArray[0].images.length > 0 ? this.leServiceArray[0].images[0].url_send : "assets/imgs/Construction.svg"
    } else {
      return "assets/imgs/Construction.svg"
    }
  }

  checkIsrayImage() {
    return Array.isArray( this.leServiceArray[0].images )
  }

  checkIsArray( arraySend ) {
    return Array.isArray( arraySend )
  }

  goToServices() {
    this.router.navigate(['/nos-services']);
  }

}
