
<div class="portfolio-content one-edge-shadow">

    <section class="page-section portfolio" id="portfolio">
        <div class="container">

            <!-- Portfolio Grid Items-->
            <div class="row justify-content-center">
                <!-- Portfolio Item 1-->
                <div class="col-md-6 col-lg-3 mb-5" *ngFor="let plst of arrayList; let i = index;" >
                    <div class="portfolio-item mx-auto" (click)="openModal(plst)" >
                        <div class="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                            <div class="portfolio-item-caption-content text-center text-white ">
                                <img src="assets/imgs/eye.svg" class="eye-img" />
                            </div>
                            
                        </div>
                        <!--<div *ngIf="plst.id === null" class="item-caption-voir-plus"> 
                            <div class="portfolio-item-caption-content text-center text-black ">
                                Voir plus ...
                            </div>
                        </div>-->

                        <img class="img-fluid-ct" [src]="plst.images[0].url_send | safeHtml " alt="Constans - {{plst.titre}}" />
                        
                    </div>
                    <div class="text-12 colorDarkHours margin-top-10">
                        {{plst.titre}}
                    </div>

                </div>

            </div>
        </div>
    </section>


</div>
