

<div *ngIf="typeDisplay === 'first' " class="row display-text-image padding-40-left-right ">

    <div class="col-md-6">

        <div *ngIf="textTitre !== ''" 
            class="titre-display text-h4 text-bold "
            [ngClass]="colorBase === 'vert' ? 'colorVert' : 'colorBleu' "
        >
            {{ textTitre }}
        </div>

        <div class="text-12" [ngClass]="colorBase === 'vert' ? 'text-justify-vert' : 'text-justify-bleu'" [innerHTML]="textBody" > </div>

    </div>

    <div class="col-md-6 text-center partie-image-div">
        <img [src]="imgeIllustrator"  class="animated bounceInRight delay1 img-illustrator " />
    </div>

</div>


<div *ngIf="typeDisplay === 'second' " class="row display-text-image padding-40-left-right static-top ">

    <div class="col-md-6 text-center partie-image-div">
        <img [src]="imgeIllustrator" class="animated bounceInLeft delay1 img-illustrator" />
    </div>
    
    <div class="col-md-6">

        <div *ngIf="textTitre !== ''" 
            class="titre-display text-h4 text-bold "
            [ngClass]="colorBase === 'vert' ? 'colorVert' : 'colorBleu' "
        >
            {{ textTitre }}
        </div>

        <div class="text-12"  ngClass]="colorBase === 'vert' ? 'text-justify-vert' : 'text-justify-bleu'" [innerHTML]="textBody" > </div>

    </div>

</div>


<div class="separator"></div>