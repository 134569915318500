import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor , HttpResponse} from '@angular/common/http';
import { Observable } from 'rxjs';

import { GlobalService } from '../services/global/global.service'
import { LoaderService } from '../services/loader/loader.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    
    private requests: HttpRequest<any>[] = [];

    constructor(
        public globalService: GlobalService,
        private loaderService: LoaderService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // Start loader
        this.loaderService.isLoading.next(true);

        // add authorization header with jwt token if available
        let token_api = this.globalService.getVisitor();
        console.log('Interceptor :: ', token_api )
        if (token_api && token_api.token) {
            
            request = request.clone({
                setHeaders: {
                    Authorization: `${token_api.token}`,
                    'Authorize-Access': token_api.token,
                }
            });
        }
        
        // Ajout de la requette dans la liste d'attente
        this.requests.push(request);

        

        return Observable.create(observer => {
          const subscription = next.handle(request)
            .subscribe(
              event => {
                if (event instanceof HttpResponse) {
                  this.removeRequest(request);
                  observer.next(event);
                }
              },
              err => {
                this.removeRequest(request);
                observer.error(err);
              },
              () => {
                this.removeRequest(request);
                observer.complete();
              });
          // remove request from queue when cancelled
          return () => {
            this.removeRequest(request);
            subscription.unsubscribe();
          };
        });

    }


    removeRequest(req: HttpRequest<any>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
          this.requests.splice(i, 1);
        }
        this.loaderService.isLoading.next(this.requests.length > 0);
    }

}
