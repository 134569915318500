import { Component, OnInit , Input, Output , EventEmitter } from '@angular/core';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';
import { ValidatorrService } from '../../services/validatorr/validatorr.service';
@Component({
  selector: 'app-formulaire',
  templateUrl: './formulaire.component.html',
  styleUrls: ['./formulaire.component.scss']
})
export class FormulaireComponent implements OnInit {

  @Input() formInput: any;
  @Input() textBouton: any = "Envoyer un message";
  @Input() colorBase = 'vert';
  @Output() formEmitter = new EventEmitter();

  formulaireGroup: FormGroup;
  submitted = false;


  constructor(
    private _formBuilder: FormBuilder
  ) { 

  }

  ngOnInit(): void {

    this.formulaireGroup = this._formBuilder.group({
      nomCtrl: [ '', Validators.required],
      prenomCtrl: [ ''],
      pwdCtrl: [ '', Validators.required],
      raisonSocialCtrl: [ ''],
      villeCtrl: [ ''],
      telCtrl: [ '', Validators.required],
      emailCtrl: ['', [ Validators.required , ValidatorrService.emailValidator] ],
      sujetCtrl: ['', Validators.required],
      messageCtrl: ['', Validators.required]
    });
    console.log( 'Befor ngAfterContentInit ', this.formInput );
    
  }

  ngAfterContentInit(): void {
    console.log( 'ngAfterContentInit ', this.formInput );
    this.initFormulaire();
  }

  initFormulaire() {
    this.submitted = false;
    this.formulaireGroup.patchValue( {
      nomCtrl: this.formInput.nomCtrl ? '' : 'PasRenseignerInForm',
      prenomCtrl: this.formInput.prenomCtrl ? '' : 'PasRenseignerInForm',
      
      raisonSocialCtrl: this.formInput.raisonSocialCtrl ? '' : 'PasRenseignerInForm',
      villeCtrl: this.formInput.villeCtrl ? '' : 'PasRenseignerInForm',
      telCtrl: this.formInput.telCtrl ? '' : 'PasRenseignerInForm',

      emailCtrl: this.formInput.emailCtrl ? '' : 'email@basemail.fr',
      pwdCtrl: this.formInput.pwdCtrl ? '' : 'PasRenseignerInForm',

      sujetCtrl: this.formInput.sujetCtrl ? '' : 'PasRenseignerInForm',
      messageCtrl: this.formInput.messageCtrl ? '' : 'PasRenseignerInForm'
    } )
  }

  get f() { 
    return this.formulaireGroup.controls; 
  }


  onSubmit() {
    this.submitted = true

    if (this.formulaireGroup.invalid) {
      return;
    }

    const formSend = {
      nom: this.f.nomCtrl.value,
      prenom: this.f.prenomCtrl.value,
      raisonSocial: this.f.raisonSocialCtrl.value,
      ville: this.f.villeCtrl.value,
      telephone: this.f.telCtrl.value,
      email: this.f.emailCtrl.value,
      pwd: this.f.pwdCtrl.value,
      subject: this.f.sujetCtrl.value,
      message: this.f.messageCtrl.value,
    }
    this.initFormulaire();
    this.formEmitter.emit( formSend );
  }

}
