
<app-header></app-header>

<div class="full-with">

    <div *ngIf="leServiceArray.length > 0" >

      <div class="row display-bouton-back">

        <div class="cta-btn cursor" (click)="goToServices()" >
          <span class="btnBleu padding-10-all text-14 text-bold btnClass">
            Retourner vers les services
          </span>
        </div>
        
      </div>

        <app-top-page [titrePage]="leServiceArray[0].titre" [colorBase]="'bleu'" ></app-top-page>

        <div class="background-white padding-bottom-10 padding-top-10 margin-top-10 row one-edge-shadow" >
          <app-display-text-image 
          [typeDisplay]="'first'" 
          [textBody]="leServiceArray[0].descriptions" 
          [textTitre]="''"
          [imgeIllustrator]="checkImage()" 
          [colorBase]="'bleu'"
          ></app-display-text-image>
        </div>
        

        <div *ngIf="checkIsArray(leServiceArray[0].listItem) && leServiceArray[0].listItem.length > 0" >
          <app-dispaly-itemlist [listOfItem]="leServiceArray[0].listItem" [titreItems] = "'C\'est aussi ... '" [colorBase]="'bleu'" ></app-dispaly-itemlist>
        </div>


        <div *ngIf=" checkIsrayImage() && leServiceArray[0].images.length > 1" >
          <app-image-gallery *ngIf=" checkIsrayImage() && leServiceArray[0].images.length > 1" [imageObject]="leServiceArray[0].images" [titreImages]="'Plus concrètement ...'" [colorBase]="'bleu'"  ></app-image-gallery>
        </div>

        
      <!-- 
        <app-dispaly-videos [videosList]="servicesData.youtubeVideo" [titreVideos]="'En quelques vidéos'" ></app-dispaly-videos>
        [videosList]="servicesData.youtubeVideo" 
      -->
        
      </div>

    <app-bandeau></app-bandeau>
    <br />

    <app-competences></app-competences>

</div>

<app-footer></app-footer>