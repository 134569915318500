
<div class=" static-top top-header" >

    <div class="div-header-title title-top-header colorBleu top-header border-red ">
        {{ leReglageArray && leReglageArray.length >  0 ? leReglageArray[0].slogan : 'L’ingénierie au cœur de vos projets' }}
    </div>

    <div class="background-white" id="navbar_top" >

        <div class="header full-with ">

            <nav class="navbar navbar-expand-lg navbar-dark ">

                <div class="navbar-brand cursor" (click)="openPage(homePage)"> 
                    <img src="assets/imgs/constans.png" class="" width="200px" />
                </div>
            
                <div class="collapse navbar-collapse" >
                    <ul class="navbar-nav ml-auto">
                        <ul class="navbar-nav mr-auto">
            
                            <li class="nav-item active"  *ngFor="let men of menuList; let idx=index" [ngClass] = "men.collaps ? 'dropdown' : ''" >
                                <a *ngIf="!men.collaps" class="nav-link waves-effect waves-light- cursor" 
                                    [ngClass] = "[idx%2===0 ? 'vertMenu' : 'bleuMenu' , men.id === selectedPage.id ? (idx%2===0 ? 'urlActiveVert' : 'urlActiveBleu') : '']" (click)="openPage(men)" 
                                >
                                {{men.libelle}}
                                </a>

                                <a *ngIf="men.collaps" class="nav-link   waves-light- cursor" id="navbarDropdown" 
                                    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    [ngClass] = "[idx%2===0 ? 'vertMenu' : 'bleuMenu' , men.id === selectedPage.id ? (idx%2===0 ? 'urlActiveVert' : 'urlActiveBleu') : '']"
                                    (click)="openPage(men)" 
                                >
                                    {{men.libelle}}
                                </a>
                                <div *ngIf="men.collaps" class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item cursor" *ngFor="let ssmen of men.collapsed" 
                                    (click)="openPage(ssmen)" [ngClass] = "ssmen.selectedID === dropDownSelected ? 'selectedSousMenu' : ''"
                                    >
                                        {{ssmen.libelle}}
                                    </a>
                                </div>

                            </li>
                            
                        </ul>
                    </ul>
                </div>
            
            </nav>
        
        </div>

    </div>

</div>

