import { Component, OnInit } from '@angular/core';

import { BackendService } from '../services/backend/backend.service';
import { GlobalService } from '../services/global/global.service'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formulaire = {
    nomCtrl: true,
    prenomCtrl: true,
    pwdCtrl: false,
    raisonSocialCtrl: true,
    villeCtrl: true,
    telCtrl: true,
    emailCtrl: true,
    sujetCtrl: true,
    messageCtrl: true
  };

  retourMessage = { ok: null, ko: null };
  colorBase = 'bleu';

  constructor(
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit( data ) {
    console.log('Data from formulaire ...... ', data);
    this.retourMessage = { ok: null, ko: null };

    this.backendService.post( `/contact/contact-us` , data )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat ==> ==> ', resultat );
         this.retourMessage.ok = resultat.text

         setTimeout(() => {
          this.retourMessage = { ok: null, ko: null };
         }, 5000);

      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })



  }

}
