import { BrowserModule } from '@angular/platform-browser';
import { NgModule , APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
import { LOCALE_ID } from '@angular/core';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as $ from 'jquery';
import { MaterialAngModule } from './matmodule/materialang.module'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderModule } from './header/header.module';
import { HomeModule } from './home/home.module';
import { LesServicesModule } from './les-services/les-services.module';
import { RealisationsModule } from './realisations/realisations.module';
import { ContactModule } from './contact/contact.module';
import { SharedModule } from './shared/shared.module';
import { PrivacyModule } from './privacy/privacy.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { JwtInterceptor } from './auth-helper/jwt.interceptor';
import { ErrorInterceptor } from './auth-helper/error.interceptor';
import { BackendService } from './services/backend/backend.service';
import { LoaderService } from './services/loader/loader.service';
import { UtilsService } from './services/utils/utils.service';
import { EventsService } from './services/events/events.service'
import { GlobalService } from './services/global/global.service'

export function loadConfigurations(backService: BackendService) {
  return () => backService.load();
}


import {MatButtonModule} from '@angular/material/button';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
   // NgbModule,
  //  AngularFontAwesomeModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule ,

  //  MaterialAngModule,

    HeaderModule,
    HomeModule,
    LesServicesModule,
    RealisationsModule,
    ContactModule,
    SharedModule,
    PrivacyModule,

    MatButtonModule
  ],
  providers: [
    BackendService,
    LoaderService,
    { provide: APP_INITIALIZER, useFactory: loadConfigurations, deps: [BackendService], multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' } ,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
