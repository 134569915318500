
<app-header></app-header>

<div class="full-with">

    <app-top-page [titrePage]="'Logiciels utilisés'" ></app-top-page>

    <div class="shadow-bottom background-white padding-20-all">
        <div class="row " *ngIf="lesLogiciels.length > 0" >

            <div class="col padding-20-all border-shadow-bottom margin-5" *ngFor="let logi of lesLogiciels[0].images" >

                <div class="row padding-20-all">
                    <div class="col-md-12 text-center">
                        <img [src]="logi.url_send | safeHtml" class="image-logo-logiciel " />
                    </div>
                    <div class="col-md-12 text-12 text-justify padding-top-10 text-bottom" [innerHTML]="logi.value_contenu" > </div>
                </div>

            </div>

        </div>
    </div>


    <br />

    <app-image-gallery [imageObject]="imagesLogicels" [titreImages]="'Réalisations ...'" ></app-image-gallery>


    <app-bandeau></app-bandeau>
    <br />

    <br />

    <app-competences></app-competences>

</div>

<app-footer></app-footer>
