import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-contact-infos',
  templateUrl: './contact-infos.component.html',
  styleUrls: ['./contact-infos.component.scss']
})
export class ContactInfosComponent implements OnInit {

  @Input() leFooterArray = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
