<div class="projet-content one-edge-shadow ">

    <div class="partie-top">
        <div class="titre-text colorDarkHours text-center section-title text-h3">
            Un service pour tous
        </div>
    </div>

    <div class="row partie-basse background-white ">

        <div class="col-md-4 text-center">
            <img src="assets/imgs/engineers.svg" class="img-engineers animated bounceInLeft delay1 " />
        </div>

        <!-- Particulier -->
        <div class="col-md-4 margin-top-40px" *ngFor="let svp of servicePourTousArray; let indexSvp = index;" >

            <div class="row">
                <div class="col-12 colorBleu">
                    <div class="section-title text-h6 "> {{svp.titre}} </div>
                </div>
            </div>

            <div class="row" *ngIf="indexSvp === 0" >
                <div class="col-12 text-12" [innerHTML]="svp.contenu" ></div>
            </div>

            <div class="row">
                <div class="col-12 padding-top-20">
                    <ul class="fa-ul">

                        <li *ngFor="let itm of maxItemList; let indItem=index " class="text-left text-12" >
                            <span class="fa-li">
                                <span class="material-icons">done</span>
                            </span>
                            {{svp.listItem[indItem].value_item_ctrl}}
                        </li>

                        <li class="text-left text-12" *ngIf="svp.listItem.length > 3">
                            <span class="fa-li">
                                <span class="material-icons">done</span>
                            </span>
                            ... et plus encore.
                        </li>

                    </ul>
                </div>
            </div>

            <div class="row">
                <div class="col-12 ">
                    <img [src]=" indexSvp === 0 ? 'assets/imgs/house.svg' : 'assets/imgs/office.svg' " class="img-illustrator animated bounceInRight delay1 " />                
                </div>
            </div>

            <div class="row" *ngIf="indexSvp === 1" >
                <div class="col-12 text-12" [innerHTML]="svp.contenu" ></div>
            </div>

            <div class="row margin-top-30px">
                <div class="col-12 cursor " (click)="openDetailPage( svp , indexSvp )">
                    <span class="btnVert padding-10-all btnClass text-12"> {{svp.textBtn}} </span>
                </div>
            </div>
            
        </div>

    </div>

</div>


