<div class="bandeau-content margin-top-10">

    <div class="row">
        <div class="col-12">
          <div class="cta-box">
            <div class="row">

              <div class="col-md-8">
                <div class="cta-text title-bandeau">
                  <span>Un projet</span> ? Laissez vos coordonnées et un conseiller vous contacte !
                </div>
              </div>

              <div class="col-md-4">
                <div class="cta-btn cursor" (click)="openURL('/nous-contacter')" >
                  <span class="btnVert padding-10-all text-14 text-bold btnClass">Contactez-nous
                      <img src="assets/imgs/contact-form.svg" class="contact-img" />
                  </span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

</div>
