

<!-- partie videos --> 
<app-top-page [titrePage]="titreVideos" *ngIf="videosList.length > 0" ></app-top-page>

<div *ngIf="videosList.length > 0" class="row display-video padding-bottom-10 padding-left-20 padding-right-30 static-top padding-top-20">
    <div class="col-md-4" *ngFor="let vty of videosList" >
        <div class="row">
            <div class="col-12">
                <app-youtube-video [videoId]="vty.videoId" ></app-youtube-video>
            </div>
        </div>
        <div class="row">
            <div class="col-12 titre-video colorBleu text-bold text-18 text-wrap">
                {{ vty.titreVideo }}
            </div>
        </div>
    </div>
</div>