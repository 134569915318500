import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal.component'


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal) { }

  public openModal(
    title: string,
    description: string,
    image: string,
    listOfImage,
    listItem: any = null,
    btnOkText: string = 'Valider',
    btnCancelText: string = 'Fermer',
    colorBase: string = 'vert',
    dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(ModalComponent, { size: dialogSize });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = description;
    modalRef.componentInstance.image = image;
    modalRef.componentInstance.listOfImage = listOfImage;
    modalRef.componentInstance.btnOkText = btnOkText;
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.listOfItem = listItem;
    modalRef.componentInstance.colorBase = colorBase;
    return modalRef.result;
  }

  
}
