
<div class="form content-div">

    <form class="contactForm" [formGroup]="formulaireGroup" (ngSubmit)="onSubmit()" >

        <div *ngIf="formInput.nomCtrl" class="form-group">
            <input formControlName="nomCtrl" type="text" class="form-control" id="nomCtrl" placeholder="Votre nom *" data-rule="minlen:4" data-msg="Veuillez saisir au moins 4 caractères" [ngClass]="{ 'is-invalid': submitted && f.nomCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.prenomCtrl" class="form-group">
            <input formControlName="prenomCtrl" type="text" class="form-control" id="prenomCtrl" placeholder="Votre prénom" data-rule="minlen:4" data-msg="Veuillez saisir au moins 4 caractères" [ngClass]="{ 'is-invalid': submitted && f.prenomCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.raisonSocialCtrl" class="form-group">
            <input formControlName="raisonSocialCtrl" type="text" class="form-control" id="raisonSocialCtrl" placeholder="Votre raison sociale" data-rule="minlen:8" data-msg="Veuillez saisir au moins 8 caractères" [ngClass]="{ 'is-invalid': submitted && f.raisonSocialCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.villeCtrl" class="form-group">
            <input formControlName="villeCtrl" type="text" class="form-control" id="villeCtrl" placeholder="Votre ville" data-rule="minlen:8" data-msg="Veuillez saisir au moins 8 caractères" [ngClass]="{ 'is-invalid': submitted && f.villeCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.telCtrl" class="form-group">
            <input formControlName="telCtrl" type="text" class="form-control" id="telCtrl" placeholder="Votre numéro de téléphone *" data-rule="minlen:6" data-msg="Veuillez saisir au moins 6 caractères" [ngClass]="{ 'is-invalid': submitted && f.telCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.emailCtrl" class="form-group">
            <input formControlName="emailCtrl" type="email" class="form-control" id="emailCtrl" placeholder="Votre Email *" data-rule="email" data-msg="Veuillez saisir un e-mail valide" [ngClass]="{ 'is-invalid': submitted && f.emailCtrl.errors }"/>
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.pwdCtrl" class="form-group">
            <input formControlName="pwdCtrl" type="password" class="form-control" id="pwdCtrl" placeholder="Votre mot de passe" data-rule="minlen:6" data-msg="Veuillez saisir au moins 6 caractères" [ngClass]="{ 'is-invalid': submitted && f.pwdCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.sujetCtrl" class="form-group">
            <input formControlName="sujetCtrl" type="text" class="form-control" id="sujetCtrl" placeholder="Sujet de votre message" data-rule="minlen:4" data-msg="Veuillez saisir au moins 8 caractères du sujet" [ngClass]="{ 'is-invalid': submitted && f.sujetCtrl.errors }" />
            <div class="validation"></div>
        </div>

        <div *ngIf="formInput.messageCtrl" class="form-group">
            <textarea formControlName="messageCtrl" class="form-control" id="messageCtrl" rows="5" data-rule="required" data-msg="Votre message ... " placeholder="Votre message " [ngClass]="{ 'is-invalid': submitted && f.messageCtrl.errors }" ></textarea>
            <div class="validation"></div>
        </div>

        <div class="text-center">
            <button type="submit" title="Send Message" 
            [ngClass]="colorBase === 'vert' ? 'buttonVertSubmit' : 'buttonBleuSubmit' "
            > {{textBouton}} </button>
        </div>

    </form>

</div>
