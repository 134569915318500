import { Component, OnInit , Input, ViewChild  } from '@angular/core';
import { NgbActiveModal, NgbCarousel , NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [NgbCarouselConfig]
})
export class ModalComponent implements OnInit {

  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnRefuserText: string;
  @Input() listOfImage = [];
  @Input() listOfItem = [];
  @Input() colorBase: string = 'vert';

  imageSelected: string;
  showNavigationArrowsWeb = true;
  showNavigationIndicatorsWeb = true;
  @ViewChild('carousel', {static : false}) carousel: NgbCarousel;
  slideEncours = 'slider_number_0'
  pauseOnHover = true;
  pauseOnFocus = false;

  constructor(
    private activeModal: NgbActiveModal,
    config: NgbCarouselConfig
  ) { 

  }

  ngOnInit() {
    console.log('this.listOfImage', this.listOfImage)
    this.imageSelected = this.listOfImage && this.listOfImage.length > 0 ? this.listOfImage[0].url_send : this.image;
  }

  public decline() {
    this.activeModal.close({etat: 'cancel'});
  }

  public accept() {
    this.activeModal.close({etat: 'accept'});
  }

  public dismiss() {
    this.decline();
  }

  choseImage(image, index) {
    // this.imageSelected = image;
    this.slideEncours = 'slider_number_' + index
    this.carousel.select( this.slideEncours ) 
  }

}
