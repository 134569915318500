<div class="portfolio-modal " >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">


            <span (click)="decline()" class="close cursor material-icons fa-3x-size displayBtnClose colorBleu" > close</span>



            <div class="modal-body text-center">
                <div class="container-pdf">
                    <div class=" justify-content-center">

                        <!-- Portfolio Modal - Title-->
                        <div *ngIf="title !== ''" class="portfolio-modal-title mb-0 text-bold-1-3em colorDarkHours " id="portfolioModal1Label">
                            {{title}}
                        </div>
                        <br /><br />
                        <a download="Plaquette-Constans.pdf" [href]="urlpdf" >
                            <span class="downloadFile cursor material-icons fa-3x-size colorBleu" >
                                file_download
                            </span>
                        </a>

                        <pdf-viewer
                            [src]="urlpdf"
                            [(page)]="page"
                            [zoom]="'-1'"
                        ></pdf-viewer>

                            <button class="btn btnClass btnBleu " (click)="decline()" >
                                <span class="material-icons fa-1x-size">close</span>
                                Fermer
                            </button>

                        <br /><br /><br />
                  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


