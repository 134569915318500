import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'
import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { UtilsService } from '../../services/utils/utils.service';

@Component({
  selector: 'app-footer-menu-mobile',
  templateUrl: './footer-menu-mobile.component.html',
  styleUrls: ['./footer-menu-mobile.component.scss']
})
export class FooterMenuMobileComponent implements OnInit {

  menuList = [];
  homePage;
  selectedPage;

  constructor( 
    private router: Router,
    private utilsService: UtilsService,
    private backendService: BackendService,
    private globalService: GlobalService
  ) { 
    this.router.events.subscribe( (val) => { 
      if (val instanceof NavigationEnd) {
        console.log('==> Mobile footer  Router change ', val)
        this.getSelectedUrl()
      }
    })


  }

  ngOnInit(): void {

    this.menuList = this.utilsService.getMenuList();
    this.getSelectedUrl();
    this.homePage = this.menuList[0];
  }


  openPage(page) {
    this.selectedPage = page;
    this.router.navigate([page.page]);
    window.scroll(0,0);
    this.getSelectedUrl();
  }

  getSelectedUrl() {
    const url = this.router.url;

    const arraySplit = url.split("/");
    arraySplit.shift()
    console.log('==> Mobile footer  Array split ', arraySplit);

    if ( ['nos-mentions-legales', 'gerer-mes-cookies' , 'qui-sommes-nous', 'logiciels-utilises'].includes( arraySplit[0] ) ) {
      this.selectedPage = this.utilsService.getMenuByPage( `/${arraySplit[0]}` )[0];
    } else {
      const page = this.menuList.filter( elt => elt.page === `/${arraySplit[0]}` );
      console.log('==> Mobile footer  router.url', this.router.url)
      console.log('==> Mobile footer  page ', page)
      this.selectedPage = page[0];

      console.log('==> Mobile footer  La page select dans le footer mobile ', this.selectedPage)
    }
    
  }

}
