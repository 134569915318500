import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-dispaly-itemlist',
  templateUrl: './dispaly-itemlist.component.html',
  styleUrls: ['./dispaly-itemlist.component.scss']
})
export class DispalyItemlistComponent implements OnInit {

  @Input() listOfItem = [];
  @Input() titreItems= ""
  @Input() colorBase = 'vert';

  constructor() { }

  ngOnInit(): void {
  }

  chechIsAyya() {
    return Array.isArray(this.listOfItem)
  }

}
