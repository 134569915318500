
<app-header></app-header>

<app-slider></app-slider>

<div class="full-with">

    

    <app-nous [quiNous]="'accueil'"></app-nous>

    
    <br />
    <app-competences></app-competences>



    <app-realisations></app-realisations>

    <br />
    <app-bandeau></app-bandeau>
    <br />


    <app-activites></app-activites>

</div>

<br />

<app-projet-start></app-projet-start>

<br /><br />

<app-footer></app-footer>
