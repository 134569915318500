import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-page',
  templateUrl: './top-page.component.html',
  styleUrls: ['./top-page.component.scss']
})
export class TopPageComponent implements OnInit {

  @Input() titrePage;
  @Input() colorBase = 'vert';
  
  constructor() { }

  ngOnInit(): void {
  }

}
