import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

  // @Input() imageObject = []; 

  imagesObject = []
  @Input() set imageObject (value ) {
    this.imagesObject = value;
    this.imagesObject.forEach(elt => {
      elt.image = elt.url_send
    })
  }

  @Input() titreImages = "";
  @Input() colorBase = 'vert';

  visiableLightbox: boolean = false;
  currentIndex: number = 0;
  currentObj = [];

  constructor() { }

  ngOnInit(): void {
    
  }

  checkIsray() {
    return Array.isArray( this.imagesObject )
  }

  showLightbox(object, index) {
      this.currentIndex = index;
      this.currentObj = object;
      console.log('=>', this.currentObj, this.currentIndex)
      this.visiableLightbox = true;
  }

  closeHandler() {
      this.visiableLightbox = false;
      this.currentIndex = -1;
  }



}
