import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-text-image',
  templateUrl: './display-text-image.component.html',
  styleUrls: ['./display-text-image.component.scss']
})
export class DisplayTextImageComponent implements OnInit {

  @Input() typeDisplay = '';
  @Input() textBody = '';
  @Input() textTitre = '';
  @Input() imgeIllustrator = "assets/imgs/Construction.svg";
  @Input() colorBase = 'vert';

  constructor() { }

  ngOnInit(): void {
    console.log( this.typeDisplay )
  }

}
