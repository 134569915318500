<div class="content-div">

    <div class="row">
        <div class="col-md-12 map mb-4 mb-lg-0 image-contact">
            <img src="assets/imgs/contact-us.svg" />
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 text-h4 colorBleu">
            Coordonnées
        </div>
    </div>

    <br />

    <app-contact-infos [leFooterArray]="infosAgenceArray" > </app-contact-infos>


    <div class="row margin-top-10" *ngIf="infosAgenceArray && infosAgenceArray.length > 0" >
        <div class="col-2">

        </div>
        <div class="col-8 text-12 colorNoirGris">

            <a *ngFor="let soc of infosAgenceArray[0].reseaux"  (click)="openUrl(soc.value_reseau_ctrl)" > 
                <img [src]="soc.icone" class="img-social cursor" /> 
            </a>

        </div>
    </div>

</div>
