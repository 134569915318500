import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio-two',
  templateUrl: './portfolio-two.component.html',
  styleUrls: ['./portfolio-two.component.scss']
})
export class PortfolioTwoComponent implements OnInit {

  @Input() arrayList = []
  constructor(
    private router: Router,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.arrayList.push( {
      images: [{url_send: 'assets/portfolio/voirplus.png'}],
      titre: '',
      id: null
    })
  }

  openModal( item ) {

    if ( item.id !== null ) {
      const images = item.listeImage ? item.listeImage : item.images ? item.images : null;
      const image = images && images.length > 0  ? images[0].url_send : item.image

      this.modalService.openModal(
        item.titre, item.descriptions, image, images, item.listItem ? item.listItem : null , 'Quitter', 'Quitter la fenêtre', 'vert'
      ).then( (result : any) => {
        console.log('result ----> ', result)
      });
    } else {
      this.voirPlus( '/nos-realisations' );
    }
    
  }


  voirPlus( page ) {
    this.router.navigate([page]);
    window.scroll(0,0);
  }

}
