import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  @Input() arrayList = []
  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }

  openModal( item ) {

    const images = item.listeImage ? item.listeImage : item.images ? item.images : null;
    const image = images && images.length > 0  ? images[0].url_send : item.image

    this.modalService.openModal(
      item.titre, item.descriptions, image, images, item.listItem ? item.listItem : null , 'Quitter', 'Quitter la fenêtre', 'vert'
    ).then( (result : any) => {
      console.log('result ----> ', result)
    })
  }

}
