
<router-outlet></router-outlet>


<app-loader>  </app-loader>


<div class="fab-container" *ngIf="windowScrolled" >
    <button mat-fab class="fab-toggler" (click)="scrollToTop()" >
      <i class="material-icons">publish</i>
    </button>
</div>
