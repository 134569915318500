import { Component, OnInit , Input } from '@angular/core';

@Component({
  selector: 'app-dispaly-videos',
  templateUrl: './dispaly-videos.component.html',
  styleUrls: ['./dispaly-videos.component.scss']
})
export class DispalyVideosComponent implements OnInit {

  @Input() videosList = []
  @Input() titreVideos = ""

  constructor() { }

  ngOnInit(): void {
  }

}
