

<app-content-param [identifiant] = "'3-3'" ></app-content-param>

<div class="competence-content static-top">

    <section id="what-we-do">
        <div class="container-fluid">
            <br />
            <div class="row ">

                <div *ngFor="let compt of competencesArray; let indexp = index;" class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 bordercolor">
                    <div class="card">
                        <div class="card-block block-{{indexp + 1}}">
                            <div class="card-title text-14 text-bold"> {{ compt.titre }} </div>
                            <div class="card-text text-14 " [innerHTML]="compt.contenu | replace: '<p></p>':'' | replace:'span':'div' " ></div>
                            
                        </div>
                    </div>
                </div>

            </div>

        </div>	
    </section>

</div>
