import { Component, OnInit , Input } from '@angular/core';
import { NgbActiveModal , NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import {
  PDFProgressData,
  PDFSource,
} from 'ng2-pdf-viewer';

declare var jQuery: any;

@Component({
  selector: 'app-load-pdf',
  templateUrl: './load-pdf.component.html',
  styleUrls: ['./load-pdf.component.scss'],
  providers: [NgbCarouselConfig]
})
export class LoadPdfComponent implements OnInit {

  @Input() title: string;
  @Input()  urlpdf: string | PDFSource | ArrayBuffer = '../assets/pdftron-sdk.pdf';

  page = 1;

  constructor(
    private activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
  }

  public decline() {
    this.activeModal.close({etat: 'cancel'});
  }

  public accept() {
    this.activeModal.close({etat: 'accept'});
  }

  public dismiss() {
    this.decline();
  }

}
