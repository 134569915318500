
<app-header></app-header>

<div class="full-with">

    <app-top-page [titrePage]="'Les réalisations de notre bureau d’étude'" ></app-top-page>


    <app-portfolio [arrayList]="lesRealisation" *ngIf="lesRealisation.length > 0" ></app-portfolio>

    <div class="voir-plus" *ngIf="lesRealisation.length > 0 && !notOther " >

        <div class="cta-btn cursor" (click)="voirPlus()" >
            <span class="btnVert padding-10-all text-14 text-bold btnClass"> 
                <img src="assets/imgs/eye.svg" class="contact-img" />
                Voir plus de réalisations
                <img src="assets/imgs/eye.svg" class="contact-img" />
            </span>
          </div>

    </div>

    <app-bandeau></app-bandeau>
    <br />

    <app-competences></app-competences>


</div>

<app-footer></app-footer>
