import { Component, OnInit } from '@angular/core';

import { BackendService } from '../../services/backend/backend.service';
import { EventsService } from '../../services/events/events.service'
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-mentions-legales',
  templateUrl: './mentions-legales.component.html',
  styleUrls: ['./mentions-legales.component.scss']
})
export class MentionsLegalesComponent implements OnInit {

  lesMensionsLegales = [];
  retourMessage = { ok: null, ko: null };


  constructor(
    private backendService: BackendService ,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
    this.displayPrivacy()
  }

  displayPrivacy() {
    this.backendService.get( `/parametrage/byidentifiant/privacy` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.lesMensionsLegales = []
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        this.lesMensionsLegales = resultat;
        console.log( 'resultat lesMensionsLegales ', this.lesMensionsLegales );
      }
     })
     .catch( error => {
      this.lesMensionsLegales = []
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }


  getDate( dateString ) {

    // var dateString = "2010-08-09 01:02:03";
    var reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
    var dateArray = reggie.exec(dateString); 
    var dateObject = new Date(
        (+dateArray[1]),
        (+dateArray[2])-1, // Careful, month starts at 0!
        (+dateArray[3]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[6])
    );

    return dateObject;
  }

}
