
<app-footer-menu-mobile> </app-footer-menu-mobile>


<div class="footer-content">

    <div class="row" *ngIf="leFooterArray && leFooterArray.length > 0" >

        <div class="col-md-3">
            <div class="titleFooter">
                Réseaux Sociaux
            </div>
            <div class="contentFooter social-links footer-links text-12 padding-top-xp">

                <div [innerHTML]="leFooterArray[0].libelle_reseaux" >  </div>
                <br /><br />

                <a *ngFor="let soc of leFooterArray[0].reseaux"  (click)="openUrl(soc.value_reseau_ctrl)" > 
                    <img [src]="soc.icone" class="img-social cursor" /> 
                </a>

                <br /><br />
                <ul class="padding-top-10">
                    <!-- <li><a class="cursor" (click)="openPage('/gerer-mes-cookies')"><i class="material-icons">done</i> Gérer mes cookies </a></li> -->
                </ul>

            </div>
        </div>

        <div class="col-md-3">
            <div class="titleFooter">
                Plan du site
            </div>
            <div class="contentFooter footer-links text-12 padding-top-xp">
                <ul>
                    <li><a class="cursor" (click)="openPage('/home')" ><i class="material-icons">done</i> Accueil</a></li>
                    <li><a class="cursor" (click)="openPage('/nos-services')" ><i class="material-icons">done</i> Services</a></li>
                    <li><a class="cursor" (click)="openPage('/nos-realisations')" ><i class="material-icons">done</i> Références</a></li>
                    <li><a class="cursor" (click)="openPage('/nous-contacter')" ><i class="material-icons">done</i> Contact</a></li>
                    <li><a  class="cursor" (click)="openPage('/qui-sommes-nous')" ><i class="material-icons">done</i> Qui sommes-nous ?</a></li>
                    <li><a class="cursor" (click)="openPage('/logiciels-utilises')"><i class="material-icons">done</i> Logiciels utilisés</a></li>
                    <li><a class="cursor" (click)="openPage('/nos-mentions-legales')"><i class="material-icons">done</i> Mentions légales</a></li>
                    <li><a class="cursor" (click)="openPlaquette()"><i class="material-icons">done</i> Plaquette Constans</a></li>
                    <!--<li><a class="cursor" (click)="openPage('/politique-de-confidentialite')"><i class="material-icons">done</i> Politique de confidentialité</a></li>-->
                    <!--<li><a class="cursor" (click)="openPage('/conditions-generales-utilisations')"><i class="material-icons">done</i> Conditions Générales d'Utilisation</a></li>-->
                </ul>
                
            </div>
        </div>

        <div class="col-md-3">
            <div class="titleFooter">
                <img src="assets/imgs/constans.png" class="logo-img" />
            </div>
            <div class="contentFooter text-12" [innerHTML]="leFooterArray[0].descriptions"></div>
        </div>

        <div class="col-md-3">
            <div class="titleFooter titleFooterContact">
                Nous contacter
            </div>
            <div class="contentFooter">
                <div class="contentFooter footer-links text-12 padding-top-xp">
                    <app-contact-infos [leFooterArray]="leFooterArray" > </app-contact-infos>
                </div>
            </div>
        </div>

    </div>

</div>

<div class="footerCopyright">
    <div class="copyright">
        &copy; Copyright <strong>Constans</strong>. Tous droits réservés.
        </div>
        <div class="credits">
        Une création de <a href=""> Constans <img src="assets/imgs/constans.png" width="100px" /> </a> et <a href="https://hachamco.fr/"> Hacham & Co <img src="assets/imgs/hachamco.png" width="120px" /> </a>
    </div>
</div>


<div class="d-blobk d-lg-none espace-from-mobile-menu">
    &nbsp;
</div>
