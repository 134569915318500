import { Component, Input, OnInit } from '@angular/core';

import { BackendService } from '../../services/backend/backend.service';
import { GlobalService } from '../../services/global/global.service'

@Component({
  selector: 'app-content-param',
  templateUrl: './content-param.component.html',
  styleUrls: ['./content-param.component.scss']
})
export class ContentParamComponent implements OnInit {

  retourMessage = { ok: null, ko: null };
  contentParam = []
  @Input() identifiant;


  constructor(
    private backendService: BackendService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.displayPage()
  }

  displayPage( ) {

    this.backendService.get( `/parametrage/byidentifiant/${this.identifiant}` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
      } else {
         console.log( 'resultat compet ', resultat );
         this.contentParam = resultat;
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement."
    })
  }

}
