
<app-content-param [identifiant] = "'3-4'" ></app-content-param>

<div class="activites-content">

    <div class="row row-flex">

        <div *ngFor="let act of listeDomaineActivite; let idx=index;" class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 cursor" (click)="openURL(act, idx)" >

            <div class=" serviceBox" [ngClass]="idx%2 === 0 ? 'coloBoxVert' : 'coloBoxBleu' " >
                <div class="service-content">
                    <div class="service-icon">
                        <i class="">
                            <img src="assets/imgs/{{act.icon}}" />
                        </i>
                    </div>
                    <div class="title text-14 text-bold colorDarkHours" [innerHTML]="act.topTitre"> </div>
                    <div class="title text-14 text-bold colorDarkHours" [innerHTML]="act.titre"> </div>
                    <div class="card-price text-center text-12" [innerHTML]="act.contenu" >  </div>
                    <div class="card-price text-center text-12 color-white" [innerHTML]="addEspaceEndText( act.contenu )" >  </div>
                        
                    <hr>
                    <div class="image-infos">
                        <img [src]="act.image" />
                    </div>
                </div>
            </div>

        </div>

    </div>


</div>
