
<app-header></app-header>

<div class="full-with">

    

    <app-top-page [titrePage]="'Contactez votre bureau d\'étude'" [colorBase]="'bleu'" ></app-top-page>

    <div class="row background-white padding-top-10 margin-bottom-10">
        <div class="col-md-6">
            <div class="row" *ngIf="retourMessage" >

                <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                    {{retourMessage.ko}}
                </div>

                <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                    {{retourMessage.ok}}
                </div>

            </div>
            
            <app-formulaire [formInput]="formulaire" (formEmitter)="onSubmit($event)" [colorBase]="colorBase" ></app-formulaire>
            
            <div class="row" *ngIf="retourMessage" >

                <div class="col-md-12 div-error" *ngIf="retourMessage.ko" >
                    {{retourMessage.ko}}
                </div>

                <div class="col-md-12 div-success" *ngIf="retourMessage.ok" >
                    {{retourMessage.ok}}
                </div>

            </div>

        </div>
        <div class="col-md-6">
            <app-infos-agence></app-infos-agence>
        </div>
    </div>


    <app-competences></app-competences>


</div>

<app-footer></app-footer>
