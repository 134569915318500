
<app-header></app-header>

<div class="full-with">

    <app-top-page [titrePage]="'Valeurs et talents de notre bureau d’étude'" ></app-top-page>

    <app-nous [quiNous]="'equipe'"></app-nous>
    <br />


    <app-bandeau></app-bandeau>
    <br />

    <app-top-page [titrePage]="'L\'équipe Constans'" ></app-top-page>

    <div class="team4 shadow-bottom background-white">

      <!-- Gerant -->
      <div class="row padding-20-all">


          <!-- column  -->
          <div class="col mb-4 border-blue" *ngFor="let grt of lesGerants" >
            <!-- Row -->
            <div class="row">
              <div class="col-md-12 text-center border-red">
                <img [src]="grt.photo | safeHtml" alt="" class="img-fluid-gerant rounded-circle" />
              </div>
              <div class="col-md-12 text-center border-red ">
                <div class="padding-top-10 text-14 text-bold colorBleu">{{grt.fonction}}</div>
                <div class="padding-top-10 text-14 text-bold colorBleu">{{grt.nom_prenom}}</div>
                <div class="subtitle mb-3 text-12 colorGris">{{grt.email}}</div>
                <div class="text-12 text-align-center" [innerHTML]="grt.presentation"></div>
              </div>
            </div>
            <!-- Row -->
          </div>
          <!-- column  -->
          
      </div>


      <div class="row padding-20-all">


        <!-- column  -->
        <div class="col-lg-3 mb-4" *ngFor="let team of lEquipe" >
          <!-- Row -->
          <div class="row">
            <div class="col-md-12 text-center">
              <img [src]="team.photo | safeHtml" alt="" class="img-fluid-teams rounded-circle" />
            </div>
            <div class="col-md-12 text-center border-red">
              <div class="padding-top-10 text-14 text-bold colorBleu">{{team.nom_prenom}}</div>
                <!-- <div class="subtitle mb-3 text-12 colorGris">{{team.email}}</div> -->
                <div class="text-12 text-align-left" [innerHTML]="team.presentation"></div>
            </div>
          </div>
          <!-- Row -->
        </div>
        <!-- column  -->
        
    </div>

    </div>
    <br />

    

    <app-competences></app-competences>

</div>

<app-footer></app-footer>
