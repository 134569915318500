
<app-header></app-header>

<div class="full-with ">

    <app-top-page [titrePage]="'Nos domaines de compétences'" [colorBase]="'bleu'" ></app-top-page>

    

    <div class="row padding-40-left-right background-white padding-bottom-10 padding-top-10 ">
        <div class="col-12">
            <mat-chip-list #chipList aria-label="Nos services " class="cursor colorBleu" >
                <mat-chip *ngFor="let svc of lesService" [selectable]="selectable" [removable]="removable" (click)="selectElement(svc)" class="cursor colorBleu" >
                  <span class="colorBleu"> {{svc.libelle}} </span>
                </mat-chip>
              </mat-chip-list>
        </div>
    </div>
    
    <div *ngIf="leServiceArray.length > 0" >

      <div class="background-white padding-bottom-10 padding-top-10 margin-top-10 row one-edge-shadow" >
        <app-display-text-image 
          [typeDisplay]="'first'" 
          [textBody]="leServiceArray[0].descriptions" 
          [textTitre]="leServiceArray[0].titre"
          [imgeIllustrator]="checkImage()"
          [colorBase]="'bleu'"
          ></app-display-text-image>
      </div>


      <div *ngIf="checkIsArray(leServiceArray[0].listItem) && leServiceArray[0].listItem.length > 0"  >
        <app-dispaly-itemlist [listOfItem]="leServiceArray[0].listItem" [titreItems] = "'C\'est aussi .. '" [colorBase]="'bleu'" ></app-dispaly-itemlist>
      </div>

      <div *ngIf=" checkIsrayImage() && leServiceArray[0].images.length > 1" >
        <app-image-gallery [imageObject]="leServiceArray[0].images" [titreImages]="'Plus concrètement ...'" [colorBase]="'bleu'"  ></app-image-gallery>
      </div>
      
      <!-- 
        <app-dispaly-videos [videosList]="servicesData.youtubeVideo" [titreVideos]="'En quelques vidéos'" ></app-dispaly-videos>
        [videosList]="servicesData.youtubeVideo" 
      -->
 
    </div>


    <app-bandeau></app-bandeau>
    <br />

    <app-competences></app-competences>

</div>

<app-footer></app-footer>
