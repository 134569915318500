
<app-header></app-header>

<div class="full-with">

    <app-top-page [titrePage]="'Mentions légales'" ></app-top-page>


    <div class="background-white " *ngFor="let mts of lesMensionsLegales">

        <div class="row" >
            <div class="col-12 padding-top-20 text-12">
                <div class="text-12 padding-40-left-right text-justify  padding-bottom-20 " [innerHTML]="mts.contenu"></div>
            </div>
        </div>

        <div class="row" >
            <div class="col-12 colorGris padding-40-left-right text-12">
                <div class="text-12 padding-40-left-right text-justify  padding-bottom-20 "> {{ getDate(mts.create_at) | date: 'EEEE d MMMM y'}} </div>
            </div>
        </div>

    </div>
    

    <br /><br /><br />


    <app-competences></app-competences>


</div>

<app-footer></app-footer>
