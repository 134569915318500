import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateformatService } from './dateformat.service'

import { 

  MatButtonModule , 
  MatIconModule, 
  MatInputModule ,
  MatAutocompleteModule,
  MatFormFieldModule,
  MatSortModule,
  MatTooltipModule,
  MatListModule,
  MatExpansionModule,
  MatBottomSheetModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatBadgeModule,
  MatMenuModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatChipsModule,
  MatCheckboxModule,
  MatStepperModule,
  MatTabsModule,
  MatDialogModule

} from '@angular/material';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    MatButtonModule , 
    MatIconModule, 
    MatInputModule ,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSortModule,
    MatTooltipModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTabsModule,
    MatDialogModule

  ],
  exports: [
    CommonModule,

    MatButtonModule , 
    MatIconModule, 
    MatInputModule ,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatSortModule,
    MatTooltipModule,
    MatListModule,
    MatExpansionModule,
    MatBottomSheetModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatMenuModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatTabsModule,
    MatDialogModule,

  ],
  providers: [DateformatService]
})
export class MaterialAngModule { }
