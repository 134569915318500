import { Component, OnInit } from '@angular/core';
import { Router , NavigationStart , NavigationEnd } from '@angular/router';
import { UtilsService } from '../services/utils/utils.service';
import {FormControl, FormGroup, Validators, FormBuilder} from '@angular/forms';


import { BackendService } from '../services/backend/backend.service';
import { GlobalService } from '../services/global/global.service'
import { startWith } from 'rxjs/operators';

declare var jQuery;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isCollapsed = false;
  selectedPage;
  dropDownSelected
  homePage;
  menuList = [];

  leReglageArray = []
  retourMessage = { ok: null, ko: null };


  constructor( 
    private router: Router,
    private utilsService: UtilsService,
    private backendService: BackendService,
    private globalService: GlobalService
  ) { 
    this.router.events.subscribe( (val) => { 
      if (val instanceof NavigationEnd) {
        console.log('Router change ', val)
        this.getSelectedUrl()
      }
    })



  }

  ngOnInit(): void {
    this.menuList = this.utilsService.getMenuList();
    this.getSelectedUrl();
    this.homePage = this.menuList[0];
    this.displayPage();


    (function($){
      $(document).ready(function(){
          var offset = $("#navbar_top").offset().top;
          $(document).scroll(function(){
              var scrollTop = $(document).scrollTop();
              if(scrollTop > offset){
                  //$("#navbar_top").css({"position": "fixed", "width": "100%", "z-index": "999"});
                  $("#navbar_top").addClass('my-fixed-top').addClass('border-bottom-header');
              }
              else {
                  //$("#navbar_top").css("position", "static");
                  $("#navbar_top").removeClass('my-fixed-top').removeClass('border-bottom-header');
              }
          });
      });
  })(jQuery);

  }


  onSelectChange() {
    console.log( 'onSelectChange ' , this.selectedPage );
    this.openPage( this.selectedPage );
  }


  openPage(page) {
    this.selectedPage = page;
    this.router.navigate([page.page]);
    window.scroll(0,0);
    this.getSelectedUrl();
  }

  getSelectedUrl() {
    const url = this.router.url;

    const arraySplit = url.split("/");
    arraySplit.shift()
    console.log('Array split ', arraySplit);

    if ( ['nos-mentions-legales', 'gerer-mes-cookies' , 'qui-sommes-nous', 'logiciels-utilises'].includes( arraySplit[0] ) ) {
      this.selectedPage = this.utilsService.getMenuByPage( `/${arraySplit[0]}` )[0];
    } else {
      const page = this.menuList.filter( elt => elt.page === `/${arraySplit[0]}` );
      console.log(this.router.url)
      console.log(page)
      this.selectedPage = page[0];
      this.dropDownSelected = arraySplit.length === 4 ? arraySplit[3] : null;
    }

    
  }


  displayPage() {

    this.backendService.get( `/reglage/list` )
    .then( resultat => {
      if( resultat.hasOwnProperty('status') && this.globalService.getErrorCode().includes( resultat.status ) ){
        this.retourMessage.ko = resultat.status != 0 ? resultat.error.error.text : "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement.!"
      } else {
        console.log( 'resultat ', resultat );
        this.leReglageArray = this.globalService.initList( resultat );
        this.leReglageArray[0].reseaux = this.globalService.iniSocialnetwork( resultat[0].reseaux )
        this.globalService.setInfosContact( this.leReglageArray );
      }
     })
     .catch( error => {
      this.retourMessage.ko = "Une erreur inattendue s'est produite. veuillez réessayer ultérieurement !"
    })
  }




  

}
